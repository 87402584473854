<template>
    <PageHeader :title="title" :items="items" />
    <faq></faq>
</template>

<script>
import PageHeader from "@/components/page-header";
import faq from '@/components/faq/index'

export default {
  data() {
    return {
      title: this.$t('qua_and_ans'),
      items: [
        {
          text: this.$t('qua_and_ans'),
          href: "/",
        }
      ],
        search: "",
        form: [],
        
    };
  },
  methods: {
  },
  computed: {
    },
  mounted() {
    
  },
  created() {
    // // ("test", this.$t)
  },
  components: {
    PageHeader,
    faq
  },
};
</script>

<style scoped>
.knowledge-base-bg {
    padding: 70px;
    color: white
}
.text-h2 {
    color: #d1e1ff;
    font-size: 37px;
    font-weight: bold;
}
</style>